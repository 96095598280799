// @import '../utilities/helpers';
@import '../config/variables';

.btn-menu{
    padding: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
    background: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #110456;
    cursor:pointer;
}

header{
    z-index: zeting(menu);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    padding: 20px 0;

    .header__logo{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 104px;
            height: 41px;
        }
    }
    .header__inputs{
        .c-login{
            img{
                width: 22px;
                height: 23px;
            }
        }
    }
    &.header{
        &__dashboard{
            background-color: webColor(mainColor);
            .header__inputs{
                position: relative;
                color:webColor(txtSecondaryColor);
                span{
                    color:webColor(txtSecondaryColor);
                }
                &_menu{
                    display: none;
                    pointer-events: none;
                    padding: 10px;
                    position: absolute;
                    top: 60px;
                    right: 0;
                    width: 195px;
                    // height: 100px;
                     background-color: webColor(txtSecondaryColor);
                    box-shadow: 0px 0px 15px 4px #72727217;
                    border-radius: 9px;
                    &>div{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 24px;
                    }
                    span{
                        color: webColor(mainColor);
                        font-size: fontSize(little);
                        font-weight: 600;
                       
                    }
                    &.open{
                        display: block;
                        pointer-events: all;
                        z-index: 1000;
                        padding: 24px 40px 24px 32px;
                    }
                }
            }
            .c-login{
                cursor: pointer;
                img{
                    filter: invert(1) grayscale(1);
                }
            }
        }
    }

    @include respond(tablet){
        padding: 20px;
    }
   
}

