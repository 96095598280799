@import '../config/variables';
footer{
    padding: 100px 0;
    background-color: webColor(mainColor);
    h6{
        font-size: 12px;
        font-weight: 700;
        color:webColor(txtSecondaryColor);
        margin-bottom: 19px;
    }
    span{
        font-size: 12px;
        font-weight: 400;
        color:webColor(txtSecondaryColor);
         margin-bottom: 19px;
    }
    a{
        color:webColor(cuaternaryColor);
        font-weight: 300;
        font-size: 12px;
        line-height: 19px;
        text-decoration: none;
    }
    strong{
        color:webColor(cuaternaryColor);
        font-weight: 300;
        font-size: 12px;
        line-height: 19px;
    }
    ul{
        display: flex;
        align-items:center;
        justify-content: space-between;
        margin: 0;
        li{
            min-width: 170px;
            max-width: 170px;
        }
    }
}
.footer__logo{
    width: 104px;
    height: 41px;
}

@include respond(bigtablet){
   footer{
        padding: 60px 0;
   } 
    .footer__logo{
        margin-bottom: 25px;
    }
    .wrapper{
        flex-direction: column !important;
        align-items: flex-start !important;
        width: 85%;
        margin: 0 auto;
    }
    .text_wrapper{
        width: 100%!important;
        ul{
            flex-direction: column !important;
            padding: 0;
            justify-content: flex-start!important;
            align-items: flex-start;
        }
        li{
            margin-bottom: 15px;
        }
    }
}
