//Vendors
// @import './vendors';
@import '/node_modules/modern-normalize/modern-normalize.css';
//Base
@import './base/typography';
@import './base/basic';

//Config
@import './config/variables';

//Globals
@import './globals/layout';
@import './globals/header';
@import './globals/footer';

//@import './libraries/material.min.css';
