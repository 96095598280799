

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/subset-WorkSans-Regular.eot');
    src: local('Work Sans Regular'), local('WorkSans-Regular'),
        url('../fonts/subset-WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-WorkSans-Regular.woff2') format('woff2'),
        url('../fonts/subset-WorkSans-Regular.woff') format('woff'),
        url('../fonts/subset-WorkSans-Regular.ttf') format('truetype'),
        url('../fonts/subset-WorkSans-Regular.svg#WorkSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/subset-WorkSans-SemiBold.eot');
    src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
        url('../fonts/subset-WorkSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-WorkSans-SemiBold.woff2') format('woff2'),
        url('../fonts/subset-WorkSans-SemiBold.woff') format('woff'),
        url('../fonts/subset-WorkSans-SemiBold.ttf') format('truetype'),
        url('../fonts/subset-WorkSans-SemiBold.svg#WorkSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/subset-WorkSans-Bold.eot');
    src: local('Work Sans Bold'), local('WorkSans-Bold'),
        url('../fonts/subset-WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-WorkSans-Bold.woff2') format('woff2'),
        url('../fonts/subset-WorkSans-Bold.woff') format('woff'),
        url('../fonts/subset-WorkSans-Bold.ttf') format('truetype'),
        url('../fonts/subset-WorkSans-Bold.svg#WorkSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../fonts/subset-WorkSans-Medium.eot');
    src: local('Work Sans Medium'), local('WorkSans-Medium'),
        url('../fonts/subset-WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-WorkSans-Medium.woff2') format('woff2'),
        url('../fonts/subset-WorkSans-Medium.woff') format('woff'),
        url('../fonts/subset-WorkSans-Medium.ttf') format('truetype'),
        url('../fonts/subset-WorkSans-Medium.svg#WorkSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

